import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const useContactUsData = () => {
  return useStaticQuery(graphql`
    {
      prismicContactUsV2 {
        data {
          address {
            richText
            text
          }
          title
          phone
          email_address_link {
            url
          }
          email_address
          contactformimage {
            alt
            gatsbyImageData
            url
          }
          body1 {
            ... on PrismicContactUsV2DataBody1Cta {
              slice_type
              primary {
                title1
                info {
                  raw
                  html
                  richText
                  text
                }
                backgroundimage {
                  alt
                  gatsbyImageData
                  url
                }
                mobile_info_text {
                  richText
                  text
                }
              }
              items {
                url {
                  url
                }
                integration_images {
                  alt
                  url
                  gatsbyImageData
                }
                mobilename
                name
              }
            }
          }
          body {
            ... on PrismicContactUsV2DataBodyGeneralCard {
              primary {
                description
                title
              }
            }
          }
        }
      }
    }
  `)?.prismicContactUsV2?.data
}

export default useContactUsData
