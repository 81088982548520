import { Layout, SEO } from "components"
import useContactUsData from "hooks/useContactUsData"
import React from "react"
import "./contactUs.scss"
import { PrismicRichText } from "@prismicio/react"
import BottomCtaSection from "components/BottomCtaSection/BottomCtaSection"
import * as yup from "yup"
import { useFormik } from "formik"
import api from "api"

const ContactUs = () => {
  const data = useContactUsData()

  const { items = [], primary = {} } = data?.body1[0]
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    text: yup.string().required("Message cannot be empty."),
  })

  const contactUsForm = useFormik({
    initialValues: { email: "", text: "" },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true)
        await api.getSupport(values)
        helpers.resetForm()
      } catch (error) {
        console.error("Form submission error", error)
      } finally {
        helpers.setSubmitting(false)
      }
    },
  })
  const {
    address = {},
    title = "",
    email_address = "",
    email_address_link = { url: "" },
    contactformimage = {},
    phone = "",
  } = data

  const firstErrorKey = Object.keys(
    contactUsForm.errors
  )[0] as keyof typeof contactUsForm.errors
  const firstErrorMessage = firstErrorKey
    ? contactUsForm.errors[firstErrorKey]
    : ""

  return (
    <Layout>
      <SEO {...data.body[0].primary} />
      <div className="ContactUs">
        <div className="header">{title}</div>

        <div className="info-container">
          <form onSubmit={contactUsForm.handleSubmit}>
            <div className="form">
              <div className="container">
                <div className="Label">Email</div>
                <input
                  type="email"
                  className={`input ${
                    contactUsForm.errors.email && contactUsForm.touched.email
                      ? "is-invalid"
                      : ""
                  }`}
                  id="formGroupEmailInput"
                  placeholder="Please enter your email"
                  {...contactUsForm.getFieldProps("email")}
                />
              </div>
              <div className="container">
                <div className="Label">Message</div>
                <textarea
                  {...contactUsForm.getFieldProps("text")}
                  id="formGroupTextInput"
                  className="text-area"
                  placeholder="Share your thoughts..."
                />
              </div>
              <div className="error-filed">
                {firstErrorMessage && (
                  <div className="invalid-feedback d-block">
                    {firstErrorMessage}
                  </div>
                )}
              </div>
              <button
                type="submit"
                disabled={contactUsForm.isSubmitting || !contactUsForm.isValid}
                className="button"
              >
                {contactUsForm.isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </div>
          </form>
          <div
            style={{ backgroundImage: `url(${contactformimage?.url})` }}
            className="address"
          >
            <div className="content">
              <div className="info">
                <div className="label">Address</div>
                <div>
                  <PrismicRichText
                    field={address?.richText}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="address">{children}</p>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="info">
                <div className="label">Phone</div>
                <div className="address">{phone}</div>
              </div>
              <div className="info">
                <div className="label">Email</div>
                <a href={email_address_link?.url} className="address">
                  {email_address}
                </a>
              </div>
            </div>
            <div
              style={{ backgroundImage: `url(${contactformimage?.url})` }}
              className="map"
            />
          </div>
        </div>
        <BottomCtaSection
          title={primary?.title1}
          desc={primary?.info}
          backgroundimage={primary?.backgroundimage}
          integration={items}
          mobileText={primary?.mobile_info_text}
        />
      </div>
    </Layout>
  )
}

export default ContactUs
